<template>
  <div
    component-name="wrapper-actions"
    class="group/wrapper-actions @container/wrapper-variants"
  >
    <div class="relative top-0 left-0 w-full">
      <div class="empty:hidden relative top-0 left-0 z-[1] w-full">
        <slot />
      </div>
      <client-only>
        <div
          v-if="
            useRemove ||
            (useGallery && useGallery.length) ||
            useFavourite ||
            useMoodboard ||
            useDownload ||
            (useConfigurator?.type && useConfigurator?.sku) ||
            useHref
          "
          class="absolute top-0 left-0 w-full h-full overflow-hidden transition-colors duration-300 ease-in-out pointer-events-none col-span-full group/wrapper-media-menu z-2 p-sm phablet:p-md"
          :class="{
            'opacity-0 group-hover/wrapper-actions:opacity-100 bg-black/0 group-hover/wrapper-actions:bg-black/10':
              $device.isDesktop,
            '!opacity-100 !bg-black/10': $device.isDesktop && forceHover,
          }"
        >
          <section
            class="flex flex-col items-stretch w-full h-full"
            :class="{
              'justify-between': useRemove,
              'justify-end': !useRemove,
            }"
          >
            <!-- HEADER -->
            <header v-if="useRemove" class="flex justify-end">
              <btn-icon
                icon="remove"
                :title="$t.labels.remove"
                variant="rounded-filled"
                size="small"
                class="pointer-events-auto"
                @click.stop.prevent="handleTrash"
              />
            </header>
            <!-- end HEADER -->
            <footer
              v-if="$device.isDesktop || ($device.isMobileOrTablet && showMenu)"
              class="flex justify-between w-full"
              :class="{
                'gap-md':
                  useHref ||
                  (useConfigurator?.type && useConfigurator?.sku) ||
                  (useGallery && useGallery.length),
              }"
            >
              <!-- FOOTER LEFT -->
              <div
                v-if="
                  useHref ||
                  (useConfigurator?.type && useConfigurator?.sku) ||
                  (useGallery && useGallery.length)
                "
                class="flex flex-col items-start justify-end w-1/4 gap-xs phablet:gap-md"
              >
                <btn-icon
                  v-if="useHref"
                  icon="external-link"
                  :title="$t.labels.go_to_link"
                  variant="rounded-filled"
                  size="small"
                  class="pointer-events-auto"
                  :action="{ href: useHref }"
                />

                <wrapper-roomvo
                  v-if="useConfigurator?.type && useConfigurator?.sku"
                  :str="useConfigurator.type"
                  :sku="useConfigurator.sku"
                  class="pointer-events-auto"
                >
                  <btn-icon
                    icon="configurator"
                    :title="$t.labels.launch_configurator"
                    variant="rounded-filled"
                    size="small"
                  />
                </wrapper-roomvo>

                <btn-icon
                  v-if="useGallery && useGallery.length"
                  :title="$t.labels.view_galery"
                  icon="gallery"
                  variant="rounded-filled"
                  size="small"
                  class="pointer-events-auto"
                  @click.stop.prevent="mediaGalleryIsOpen = true"
                />
              </div>
              <div v-else class="grow-0"></div>
              <!-- end FOOTER LEFT -->

              <!--  FOOTER RIGHT -->
              <div
                class="flex flex-col items-end justify-end @[260px]/wrapper-variants:flex-row gap-xs phablet:gap-md"
                :class="{
                  'w-full':
                    !useHref &&
                    !(useConfigurator?.type && useConfigurator?.sku) &&
                    (!useGallery || !useGallery.length),
                  'w-3/4':
                    useHref ||
                    (useConfigurator?.type && useConfigurator?.sku) ||
                    (useGallery && useGallery.length),
                }"
              >
                <btn-add-to-moodboard
                  v-if="useMoodboard"
                  ref="tpl_btnAddToMoodboard"
                  size="small"
                  class="pointer-events-auto"
                  :slug="useMoodboard.slug || undefined"
                  :img-id="useMoodboard.img_id || undefined"
                />

                <btn-handle-favourite
                  v-if="useFavourite"
                  ref="tpl_btnHandleFavourite"
                  size="small"
                  class="pointer-events-auto"
                  :slug="useFavourite.slug || undefined"
                  :img-id="useFavourite.img_id || undefined"
                />

                <btn-icon
                  v-if="useDownload"
                  icon="download"
                  variant="rounded-filled"
                  size="small"
                  class="pointer-events-auto"
                  :title="$t.labels.download"
                  :action="{
                    href: asset.origin_id,
                    cdn: asset.cdn,
                    filename: asset.alt || asset.origin_id.split('/').pop(),
                    type: 'image',
                  }"
                />
              </div>
              <!-- end FOOTER RIGHT -->
            </footer>
            <footer
              v-else-if="$device.isMobileOrTablet"
              class="flex justify-end w-full"
            >
              <btn-icon
                title="Kebab"
                size="small"
                icon="kebab"
                variant="rounded-filled"
                class="pointer-events-auto"
                @click.stop.prevent="showMenu = true"
              />
            </footer>
          </section>
        </div>
      </client-only>
    </div>

    <template v-if="useGallery && useGallery.length">
      <modal-media-gallery
        :is-open="mediaGalleryIsOpen"
        :items="useGallery"
        @close="mediaGalleryIsOpen = false"
      />
    </template>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";

const { y: windowScrollY } = useWindowScroll();
const { width: windowW, height: windowH } = useWindowSize();
const tpl_btnHandleFavourite = templateRef("tpl_btnHandleFavourite");
const tpl_btnAddToMoodboard = templateRef("tpl_btnAddToMoodboard");
const showMenu = ref(false);

const props = defineProps({
  useRemove: {
    type: [Boolean, Object],
    required: false,
    default: false,
  },
  useGallery: {
    type: Array,
    required: false,
  },
  useFavourite: {
    type: [Boolean, Object],
    required: false,
    default: false,
  },
  isFavourite: {
    type: Boolean,
    required: false,
    default: false,
  },
  useConfigurator: {
    type: Object,
    required: false,
  },
  useHref: {
    type: String,
    required: false,
  },
  useDownload: {
    type: Boolean,
    required: false,
    default: false,
  },
  useMoodboard: {
    type: [Boolean, Object],
    required: false,
    default: false,
  },
  forceHover: {
    type: Boolean,
    required: false,
    default: false,
  },
  asset: {
    type: Object,
    required: true,
    default: false,
  },
});

const { isMobileOrTablet } = useDevice();
const mediaGalleryIsOpen = ref(false);
const userStore = useUserStore();
const nuxtApp = useNuxtApp();
const { params, name } = useRoute();

function handleTrash() {
  const data = {
    moodboard_slug:
      name == "country-locale-account-personal-area-favourites"
        ? "favourites"
        : params.id,
  };

  if (props.useRemove.slug) {
    data.product_slug = props.useRemove.slug;
  } else {
    data.img_id = props.useRemove.img_id;
  }

  userStore
    .removeFromMoodboard(data)
    .then((response) => {
      if (!response) {
        console.log(nuxtApp.$t.labels.something_went_wrong);
      }
      const { status, error } = response;

      if (status == "error") {
        console.log(nuxtApp.$t.labels.something_went_wrong);
      }

      return response;
    })
    .catch((error) => {
      console.log("error", error);
      console.log(nuxtApp.$t.labels.something_went_wrong);
    });
}

watchThrottled(
  [windowW, windowH, windowScrollY],
  () => {
    showMenu.value = false;
  },
  { throttle: 500 }
);
</script>
