<template>
  <client-only>
    <TransitionRoot :show="isOpen" as="template">
      <Dialog
        component-name="modal-media-gallery"
        class="relative z-50"
        data-skin="white"
        data-lenis-prevent
      >
        <TransitionChild
          enter="transition-opacity duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <!-- The backdrop, rendered as a fixed sibling to the panel container -->
          <div
            class="fixed inset-0 bg-black/50"
            aria-hidden="true"
            @click="$emit('close')"
          ></div>
        </TransitionChild>

        <!-- Full-screen scrollable container -->
        <div
          class="pointer-events-none overflow-clip fixed inset-0 w-full h-full"
        >
          <!-- Container to center the panel -->
          <div class="flex flex-col w-full h-screen">
            <TransitionChild
              enter="transition-transform duration-300 ease-out delay-200"
              enter-from="translate-y-full"
              enter-to="translate-y-0"
              leave="transition-transform duration-300 ease-in"
              leave-from="translate-y-0"
              leave-to="translate-y-full"
            >
              <!-- The actual dialog panel -->
              <component
                :is="$device.isMobile ? 'div' : DialogPanel"
                data-skin="white"
                class="pointer-events-auto overflow-auto overscroll-contain w-full h-screen max-h-screen pb-[calc(100lvh-100svh)] !m-0 !p-0 bg-skin-base"
              >
                <div class="flex flex-col w-full">
                  <toolbar-modal-header
                    :label="$t.labels.gallery"
                    @close="$emit('close')"
                    class="sticky top-0 left-0 z-10 w-full"
                  >
                    <template v-if="isLaptop" #header-left>
                      <div
                        class="flex gap-sm items-center h-full text-skin-base"
                      >
                        <span
                          class="text-button"
                          v-html="$t.labels.change_view"
                        ></span>

                        <btn-icon
                          icon="thumbs-grid"
                          :title="'Grid'"
                          :class="{
                            'opacity-60':
                              modalMediaGalleryThumbsCookie === 'list',
                          }"
                          :pressed="
                            modalMediaGalleryThumbsCookie === 'grid' ||
                            !modalMediaGalleryThumbsCookie
                          "
                          @click="modalMediaGalleryThumbsCookie = 'grid'"
                        />

                        <btn-icon
                          icon="thumbs-list"
                          :title="'List'"
                          :class="{
                            'opacity-60':
                              modalMediaGalleryThumbsCookie === 'grid' ||
                              !modalMediaGalleryThumbsCookie,
                          }"
                          :pressed="modalMediaGalleryThumbsCookie === 'list'"
                          @click="modalMediaGalleryThumbsCookie = 'list'"
                        />
                      </div>
                    </template>
                  </toolbar-modal-header>

                  <transition-fade duration="quick">
                    <grid-media
                      v-if="
                        modalMediaGalleryThumbsCookie === 'grid' ||
                        !modalMediaGalleryThumbsCookie ||
                        !isLaptop
                      "
                      key="grid"
                      :items="items"
                      loading="auto"
                      :lazy-Index="0"
                      class="w-full px-edge py-4xl"
                      :use-favourite="true"
                      :use-moodboard="true"
                      :use-download="true"
                    />

                    <section
                      v-else-if="modalMediaGalleryThumbsCookie === 'list'"
                      key="list"
                      class="grid grid-cols-2 grid-flow-dense items-center w-full col-span-full gap-x-ui gap-y-4xl px-edge py-4xl"
                    >
                      <card-media
                        v-for="(item, index) in items"
                        :asset="{
                          ...item.asset,
                          sizes: {
                            base:
                              item.asset.aspectRatio === '4 / 5' ? 300 : 600,
                            phablet:
                              item.asset.aspectRatio === '4 / 5' ? 500 : 1000,
                            tablet:
                              item.asset.aspectRatio === '4 / 5' ? 650 : 1250,
                            laptop:
                              item.asset.aspectRatio === '4 / 5' ? 750 : 1500,
                            desktop:
                              item.asset.aspectRatio === '4 / 5' ? 950 : 1900,
                            extdesktop:
                              item.asset.aspectRatio === '4 / 5' ? 1300 : 2560,
                          },
                          loading: 'auto',
                        }"
                        :use-gallery="false"
                        :use-favourite="true"
                        :use-moodboard="true"
                        :use-download="true"
                        class="w-full"
                        :class="{
                          'col-span-1': item.asset.aspectRatio === '4 / 5',
                          'col-span-2': item.asset.aspectRatio !== '4 / 5',
                        }"
                      />
                    </section>
                  </transition-fade>
                </div>
              </component>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </client-only>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
  items: {
    type: Array,
    required: true,
  },
});

const route = useRoute();
const emit = defineEmits(["close"]);
const isLaptop = useMediaQuery("(min-width: 1272px)");

const modalMediaGalleryThumbsCookie = useCookie(
  "mrg_modal-media-gallery-thumbs",
  {
    maxAge: 60 * 60 * 24 * 365,
  }
);

watch(route, (newVal, oldVal) => {
  emit("close");
});
</script>
